<template>
  <v-layout>
    <app-loader
      v-if="!pageLoaded"
      title="Загрузка данных Условий"
      :loading="!pageLoaded"
    />
    <v-container
      v-else
      class="d-flex justify-center"
      column
    >
      <v-card
        v-if="!noData"
        tile
        class="mt-0 mb-1 ml-0 pa-2 elevation-1"
        full-width
        :style="{
          height: `${blockHeight + 'px'}`,
          'overflow-y': 'auto'
        }"
      >
        <v-card-title>
          <h1 class="h3 mb-2">
            {{ title }}
          </h1>
        </v-card-title>

        <v-card-text>
          <v-row class="d-flex justify-center mb-1">
            <div>
              <v-img
                :src="`${$url}${posterPath}`"
              />
            </div>
          </v-row>
          <v-row
            style="max-width: 100%; width: 100%; overflow-x: auto"
          >
            <span
              style="max-width: 100%; width: 100%; overflow-x: auto"
              v-html="value"
            />
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        v-else
        tile
        class="mt-0 mb-1 ml-0 pa-2 elevation-1"
        full-width
        :style="{
          height: `${blockHeight + 'px'}`,
          'width': '100%',
          'overflow-y': 'auto'
        }"
      >
        <v-card-title><p>Нет данных об условиях</p></v-card-title>
      </v-card>
    </v-container>
  </v-layout>
</template>

<script>
import * as termsApi from "../../backend/termsApi";

export default {
  data() {
    return {
      title: "",
      value: "",
      posterPath: "",
      date: "",
      pageLoaded: false,
      noData: false,
    };
  },
  computed: {
    blockHeight() {
      return window.innerHeight - 220;
    },
  },
  async created() {
    const term = await this.getTermsList();
    if (term) {
      await this.getTerms(term.link);
    } else {
      this.noData = true;
      this.pageLoaded = true;
    }
  },
  methods: {
    async getTermsList() {
      this.pageLoaded = false;
      const res = await termsApi.getTermsList();
      return res.result[0];
    },
    async getTerms(link) {
      const res = await termsApi.getTerms(link);
      this.title = res.title;
      this.value = res.value;
      this.posterPath = res.posterPath;
      this.date = res.createdAt;
      this.pageLoaded = true;
    },
  },
};
</script>
