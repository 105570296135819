import http from "./http.js";

async function getTermsList() {
  const response = await http.get("terms");
  return response.data;
}

async function getTerms(link) {
  const response = await http.get(`terms/${link}`);
  return response.data;
}

export {
  getTermsList,
  getTerms,
};